import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/react-stateless-functional-components",
  "date": "2016-06-16",
  "title": "REACT STATELESS FUNCTIONAL COMPONENTS",
  "author": "admin",
  "tags": ["development", "javascript", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "React stateless functional components are pure JavaScript functions that only have a render() function and no lifecycle methods like componentDidMount."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What are React Stateless Functional Components?`}</h2>
    <p>{`React stateless functional components are pure JavaScript functions that only have a `}<inlineCode parentName="p">{`render()`}</inlineCode>{` function and no lifecycle methods like `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`.`}</p>
    <h2>{`Advantages of Stateless Functional Components`}</h2>
    <p>{`Let’s look at why Stateless Functional Components are beneficial.`}</p>
    <h3>{`No this keyword`}</h3>
    <p>{`No need of classes means, you don’t have to bind the callbacks to the class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// With classes
onClick={this.sayHello.bind(this)}>Say Hello</a>
// Without classes
onClick={sayHello}>Say Hi</a>
`}</code></pre>
    <p>{`This will be helpful for the developers who are struggling with understanding `}<inlineCode parentName="p">{`this`}</inlineCode>{` keyword.`}</p>
    <h3>{`No Classes Required`}</h3>
    <p>{`Not using classes means we can avoid using classes! That also means we can do without `}<inlineCode parentName="p">{`extends`}</inlineCode>{` and `}<inlineCode parentName="p">{`constructor`}</inlineCode>{`.`}</p>
    <h3>{`Leave state to the container`}</h3>
    <p>{`Stateless functional components are best for the presentational components, which focus on the UI. This will leave container components to manage the state and pass down data as props to the presentational components.`}</p>
    <h3>{`Less Noise`}</h3>
    <p>{`Stateless functional components require less lines of code compared to classes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
 
class Car extends React.Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    return (
      <div>
        {\`Brand: \${this.props.brand}\`}
      </div>
    );
  }
}
 
export default Car;
`}</code></pre>
    <p>{`By using stateless functional components, this can be written in lesser number of lines.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
 
const Car = ({brand}) => {
    return (
      <div>
        {\`Brand: \${brand}\`}
      </div>
    );
};
export default Car;
`}</code></pre>
    <h3>{`Best Practice`}</h3>
    <p>{`Presentational components focus solely on the UI, so state should be avoided in them. Only container components should handle lifecycle hooks. This makes functional components an ideal candidate for this role.`}</p>
    <h3>{`High Testability`}</h3>
    <p>{`Stateless functional components can be easily tested in pure isolation. Not having to deal with state, makes this an easy task.`}</p>
    <h3>{`Performant`}</h3>
    <p>{`No state management and lifecycle methods means there’s no need to allocated memory for those components. This results in improved performance.`}</p>
    <h3>{`Conclusion`}</h3>
    <p>{`React’s stateless functional components bring us highly performant, testable, and readable way of writing components.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      